/* cocktailList.css */
.cocktail-list {
  padding: 20px;
}

.cocktail-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
}

.list-title {
  font-size: 3rem;
  margin-bottom: 2rem;
}

.cocktail-item {
  background-color: #f5f5f5;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  text-align: center;
  transition: transform 0.3s ease;
  width: 90%;
}

.cocktail-item:hover {
  transform: translateY(-5px);
}

.cocktail-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.cocktail-title {
  padding: 10px;
  font-size: 1.2rem;
  margin: 0;
  color: #000;
  text-decoration: none;
}

