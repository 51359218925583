.cocktail-page {
  font-family: "Helvetica Neue", sans-serif;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  color: #000;
}

.image-wrapper {
  text-align: center;
  margin-bottom: 20px;
}

.cocktail-image {
  max-width: 100%;
  border-radius: 10px;
}

.cocktail-name {
  text-align: center;
  font-size: 2.5rem!important;
  color: #000000;
  margin-bottom: 30px;
}

h2{
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.ingredients {
  margin-bottom: 30px;
  text-align: center;

  ul {
    list-style-type: none;
    padding: 0;
  }

  .ingredient-item {
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 5px;

    &:nth-child(even) {
      background-color: #fff;
    }
  }
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.info-box {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  background-color: #f4f4f4;
  text-align: center;

  h3 {
    margin-bottom: 5px;
    font-size: 1.2rem;
  }

  p {
    font-size: 1rem;
  }
}
